<template>
  <section>
    <div class="row hidden-xs hidden-sm" style="margin-bottom:-10px">
      <div class="col-sm-3">
        <label for="">{{ $t("name") }}</label>
      </div>
      <div class="col-sm-3 form-group form-group-sm">
        <label for="">{{ $t("title") }}</label>
      </div>
      <div class="col-sm-3 form-group form-group-sm">
        <label for="">{{ $t("value") }}</label>
      </div>
      <div class="col-sm-3 form-group form-group-sm">
        <label for="">{{ $t("options") }}</label>
      </div>
    </div>
    <draggable v-model="items" v-if="items.length">
      <div class="row" v-for="(item, ix) in items" :key="ix">
        <div class="move-item">
          <div
            class="col-sm-3 form-group form-group-sm"
            :class="{'has-error': validation[item.name].error}"
          >
            <div class="move-item move-item-icon hidden-xs hidden-sm">
              <i class="glyphicon glyphicon-option-vertical"></i>
            </div>
            <label for="" class="label-mobile hidden-md hidden-lg">{{
              $t("name")
            }}</label>
            <input
              type="text"
              class="form-control field-name"
              v-model="item.name"
              :disabled="busy"
              ref="fieldName"
            />
          </div>
          <div class="col-sm-3 form-group form-group-sm">
            <label for="" class="label-mobile hidden-md hidden-lg">{{
              $t("title")
            }}</label>
            <input
              type="text"
              class="form-control "
              v-model="item.title"
              :disabled="busy"
            />
          </div>
          <div class="col-sm-3 form-group form-group-sm">
            <label for="" class="label-mobile hidden-md hidden-lg">{{
              $t("value")
            }}</label>
            <input
              type="text"
              class="form-control "
              v-model="item.value"
              :disabled="busy"
            />
          </div>
          <div class="col-sm-3 form-group form-group-sm">
            <label class="checkbox-inline">
              <input type="checkbox" v-model="item.required" :disabled="busy" />
              {{ $tc("required", 1) }}
            </label>
            <div class="pull-right">
              <span
                class="btn"
                @click.stop.prevent="removeItem(ix)"
                :disabled="busy"
                ><i class="fa fa-trash"></i
              ></span>
            </div>
          </div>
        </div>
        <div class="sep hidden-md hidden-lg"></div>
      </div>
    </draggable>
    <div class="alert alert-default alert-dismissible" v-else>
      <button
        type="button"
        class="close"
        data-dismiss="alert"
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </button>
      {{ $t("there_are_no_items_configured") }}
    </div>
    <div class="actions">
      <button
        v-if="resetButton"
        class="btn btn-default"
        @click.stop.prevent="removeAll()"
      >
        <i class="fa fa-close"></i> {{ $tc("remove", 1) }} {{ $tc("all", 1) }}
      </button>
      <button
        v-if="restoreButton"
        class="btn btn-default"
        @click.stop.prevent="$emit('restore')"
      >
        <i class="fa fa-undo"></i> {{ $tc("restore", 1) }}
      </button>
      <button
        v-if="importButton && hasMissingContractFields"
        class="btn btn-default"
        @click.stop.prevent="importFromContract()"
      >
        <i class="fa fa-plus-circle"></i>
        {{ $tc("import_from_contract", 1) }}
      </button>
      <button
        v-if="btnNewLine"
        class="btn btn-default"
        @click.stop.prevent="newItem"
        :disabled="busy"
      >
        <i class="fa fa-pencil"></i> {{ $tc("new_field", 1) }}
      </button>
    </div>
  </section>
</template>

<script>
import {isEqual} from "lodash";
import draggable from "vuedraggable";

export const defaultItem = () => ({
  name: "",
  title: "",
  required: false,
  value: ""
});

export default {
  name: "FormExtendedProperties",
  props: {
    value: {
      type: Array,
      required: false,
      default: () => []
    },
    resetButton: {
      type: Boolean,
      required: false,
      default: false
    },
    restoreButton: {
      type: Boolean,
      required: false,
      default: false
    },
    importButton: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  components: {
    draggable
  },
  data() {
    return {
      items: [],
      item: defaultItem(),
      busy: false
    };
  },
  computed: {
    btnNewLine() {
      let items = this.items || [];
      if (!items.length || items[items.length - 1].name) return true;
      return false;
    },
    validation() {
      let entry = {};
      let names = this.items.map(({name}) => name);
      names.forEach(function(x) {
        entry[x] = entry[x] || {};
        entry[x].count = (entry[x].count || 0) + 1;
        entry[x].error = entry[x].count > 1;
      });
      return entry;
    },
    contract() {
      return this.$store.getters["user/contract"] || null;
    },
    hasMissingContractFields() {
      let source =
        this?.contract?.portal_data?.equipment_extended_properties || [];
      for (var i in source || []) {
        if (!(this.items || []).some(({name}) => name == source[i].name)) {
          return true;
        }
      }
      return false;
    }
  },
  watch: {
    value: {
      handler(n) {
        let items = JSON.parse(JSON.stringify(n));
        if (isEqual(items, this.items)) return;
        this.$set(this, "items", items);
      },
      immediate: true,
      deep: true
    },
    items: {
      handler(n) {
        if (isEqual(n, this.value)) return;
        this.$emit("input", n);
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    newItem() {
      let items = JSON.parse(JSON.stringify(this.items));
      items.push(defaultItem());
      this.$set(this, "items", items);
      this.$nextTick(() => {
        if (this?.$refs?.fieldName?.length) {
          this.$refs.fieldName[this.$refs.fieldName.length - 1].focus();
        }
      });
    },
    removeItem(ix) {
      if (ix >= 0 && ix < this.items.length) {
        let items = JSON.parse(JSON.stringify(this.items));
        items.splice(ix, 1);
        this.$set(this, "items", items);
      }
    },
    removeAll() {
      this.$set(this, "items", []);
    },
    importFromContract() {
      let source =
        this?.contract?.portal_data?.equipment_extended_properties || [];
      let lst = JSON.parse(JSON.stringify(this.items || []));
      let nFields = lst.length;
      source.forEach((prop) => {
        if (!lst.find(({name}) => name == prop.name)) {
          lst.push(JSON.parse(JSON.stringify(prop)));
        }
      });
      if (lst.length > nFields) {
        this.$set(this, "items", lst);
      }
    }
  }
};
</script>

<style scoped>
.actions {
  margin: 20px 0;
}

.actions > button {
  margin: 0 7px;
}

.move-item:hover {
  cursor: move;
  opacity: 0.8;
  background-color: whitesmoke;
}

.move-item-icon {
  position: absolute;
  left: 4px;
  top: 4px;
  color: #666;
  margin-right: 7px;
}

.form-group {
  margin: 0;
}

div.table-title {
  padding: 0 0 5px 0;
  margin-bottom: 5px;
  border-bottom: 1px solid #cdcdcd;
}

div.table-title .label {
  font-size: 14pt;
  color: #777;
  text-align: left;
}

.alert-default {
  margin-top: 10px;
  background-color: whitesmoke;
}

.sep {
  width: 95%;
  margin: 0 auto 10px auto;
  padding-bottom: 1px;
  border-bottom: 1px solid rgb(205, 205, 205);
  clear: both;
  padding-top: 10px;
}

.label-mobile {
  margin-top: 5px;
  margin-bottom: 0;
}
</style>
