<template>
  <div v-if="contract" class="me">
    <div class="formData" v-if="showForm">
      <div class="table-title">
        <div style="display:flex;justify-content:space-between;">
          <div>
            <span
              class="btn btn-xs btn-default"
              @click.stop.prevent="showForm = false"
            >
              <i class="fa fa-arrow-left"></i> {{ $t("back") }}
            </span>
          </div>
          <div class="text-center">
            <span class="clicable no-select label">
              {{ $t(formData.id ? "edit_token" : "create_new_token") }}
            </span>
          </div>
          <div>
            <span
              class="btn btn-default btn-xs"
              @click.stop.prevent="showForm = false"
            >
              <i class="fa fa-close"></i>
            </span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="row">
            <div class="col-md-9">
              <div class="form-group form-group-sm">
                <label for="description">
                  {{ $t("description") }}
                </label>
                <input
                  type="text"
                  class="form-control"
                  v-model="formData.description"
                  ref="description"
                />
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group text-center">
                <label for="">{{ $tc("enabled", 1) }}</label>
                <div class="input-control clicable">
                  <span
                    style="font-size: 150%"
                    @click.stop.prevent="formData.enabled = !formData.enabled"
                    :title="$t(formData.enabled ? 'on' : 'off')"
                  >
                    <i
                      class="fa fa-toggle-on text-green"
                      v-if="formData.enabled"
                    ></i>
                    <i class="fa fa-toggle-off" v-else></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group form-group-sm">
                <label for="mqtt_acl_level">
                  {{ $t("restrictions") }}
                </label>
                <select
                  name="mqtt_acl_level"
                  id="mqtt_acl_level"
                  class="form-control"
                  v-model="formData.broker_mqtt_acl_level_id"
                >
                  <option
                    v-for="(item, ix) in brokerMqttAclLevels"
                    :key="ix"
                    :title="item.description"
                    :value="item.id"
                  >
                    {{ item.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="row" v-if="formData.id">
            <div class="col-xs-12">
              <div class="form-group form-group-sm">
                <div class="input-group">
                  <div class="input-group-addon">token</div>
                  <input
                    type="text"
                    class="form-control"
                    disabled
                    :value="formData.token"
                  />
                  <div
                    class="input-group-addon"
                    @click.stop.prevent="copyToClipboard(formData.token)"
                  >
                    <i class="fa fa-copy"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group has-feedback">
            <div class="input-group">
              <div class="input-group-addon">
                <span
                  class="clicable no-select"
                  @click.stop.prevent="toggleConnector()"
                  :title="$t('hints.connector_list_acl')"
                >
                  <i
                    :class="
                      allChecked ? 'fa fa-check-square-o' : 'fa fa-square-o'
                    "
                  ></i>
                  {{ $t("connectors") }}
                </span>
              </div>
              <input
                name="connectorQuery"
                type="text"
                class="form-control"
                v-model="connectorQuery"
                autocomplete="off"
                style="background-color:transparent"
                ref="connectorQuery"
              />
              <span
                class="form-control-feedback clicable fa "
                :class="connectorQuery ? 'fa-close' : 'fa-search'"
                @click="
                  connectorQuery = '';
                  $refs.connectorQuery.focus();
                "
                style="z-index:3"
              ></span>
            </div>

            <div class="group-list">
              <div
                class="item clicable"
                v-for="(item, ix1) in parsedConnectorList"
                :key="ix1"
                @click.stop.prevent="toggleConnector(item.id)"
                :title="`${item.id} - ${item.description}`"
              >
                <i
                  :class="
                    item.checked ? 'fa fa-check-square-o' : 'fa fa-square-o'
                  "
                ></i>
                <span>
                  {{ item.name }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="display:flex;justify-content:center;gap: 25%;margin-top:20px">
        <span
          v-if="formData.id"
          class="btn btn-default"
          @click.stop.prevent="remove(formData)"
          :title="$tc('remove')"
          :disabled="!isValid || busy"
        >
          <i :class="busy ? 'fa fa-refresh fa-spin' : 'fa fa-trash'"></i>
          {{ $tc("remove") }}
        </span>
        <span
          class="btn btn-primary"
          @click.stop.prevent="save(formData)"
          :title="$tc('confirm')"
          :disabled="!isValid || busy"
        >
          <i :class="busy ? 'fa fa-refresh fa-spin' : 'fa fa-save'"></i>
          {{ $tc("confirm") }}
        </span>
      </div>
    </div>
    <div v-else>
      <div class="table-title">
        <slot name="title">
          <div class="row">
            <div class="col-xs-12 label">
              {{ $t("configured_items") }}
            </div>
          </div>
        </slot>
      </div>
      <Spin v-if="busy" />
      <SearchableTable
        class="searchable-table"
        v-if="!busy && items && items.length"
        :searchEnabled="false"
        :items="sortedItens"
        :fields="fields"
        :commands="commands"
        @command="onCommand"
        @select="edit($event)"
      >
        <template v-slot:toggle="entry">
          <span @click.stop="toggleItem(entry.item)" style="z-index:9">
            {{ $t(entry.item.enabled ? "yes" : "no") }}
            <i
              :class="
                entry.item.enabled
                  ? 'fa fa-toggle-on text-green'
                  : 'fa fa-toggle-off'
              "
            ></i>
          </span>
        </template>
        <template v-slot:token="entry">
          <div
            class="clicable token-cell"
            :class="clipboardText == entry.item.token ? 'text-warning' : ''"
          >
            <span>{{ entry.item.token }}</span>
            <span
              class="copy-icon"
              :title="$t('copy')"
              @click.stop.prevent="copyToClipboard(entry.item.token)"
            >
              <i class="fa fa-copy"></i
            ></span>
          </div>
        </template>
      </SearchableTable>
      <div v-if="!busy && items && !items.length">
        <div class="alert alert-default alert-dismissible">
          <button
            type="button"
            class="close"
            data-dismiss="alert"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          {{ $t("there_are_no_items_configured") }}
        </div>
        <span
          v-if="!showForm"
          class="btn btn-default"
          @click.prevent.stop="addNew"
          >{{ $t("create_a_token") }}</span
        >
      </div>
      <div>
        <div class="text-center" style="margin: 20px">
          <button class="btn btn-primary" @click.stop.prevent="addNew()">
            <i class="fa fa-plus"></i>
            {{ $tc("new", 1) }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {isMQTT} from "@/services/connector.js";
import TokenService from "@/services/token.js";
import SearchableTable from "@/components/searchable-table.vue";
import Spin from "@/components/spin";

const initialState = () => {
  return {
    busy: false,
    connectorQuery: "",
    formData: {
      id: "",
      etag: "",
      broker_mqtt_acl_level_id: 1,
      description: "",
      enabled: true,
      token: "",
      allowed_connectors_ids: []
    },
    items: [],
    showForm: false,
    clipboardText: ""
  };
};
export default {
  name: "FormContractTokens",
  props: {},
  components: {
    SearchableTable,
    Spin
  },
  data() {
    return initialState();
  },
  computed: {
    contract() {
      return this.$store.getters["user/contract"] || null;
    },
    brokerMqttAclLevels() {
      return this.$root.config.references.broker_mqtt_acl_levels || [];
    },
    isValid() {
      return this.formData.description != "";
    },
    fields() {
      let self = this;
      return [
        {
          name: "token",
          title: "Token"
        },
        {
          name: "description",
          title: "description"
        },
        {
          name: "broker_mqtt_acl_level_id",
          title: "restrictions",
          parser: (item) => {
            return (
              self.brokerMqttAclLevels.find(
                ({id}) => id == item.broker_mqtt_acl_level_id
              ).name || ""
            );
          }
        },
        {
          name: "connectors",
          title: "connectors",
          parser: (item) => {
            let all = this.connectorList.length;
            let sel = (item.allowed_connectors || []).length;
            return !sel || sel == all
              ? `${this.$tc("all", 1)} (${all})`
              : `${sel} ${this.$tc("connector", sel > 1 ? 2 : 1)}`;
          }
        },
        {
          name: "toggle",
          title: "enabled",
          style: {
            "text-align": "center"
          }
        }
      ];
    },
    commands() {
      if (this.$can("manage", "TokenAcessoRemotoEscrita")) {
        return [
          {name: "edit", title: "edit", icon: "fa fa-pencil"},
          {
            name: "copy",
            title: this.$t("copy", {item: "token"}),
            icon: "fa fa-copy"
          },
          {name: "remove", title: "remove", icon: "fa fa-trash"}
        ];
      }
      return [];
    },
    connectorList() {
      return this.$store.getters["equipmentList"] || [];
    },
    filteredConnectorList() {
      return this.connectorList
        .filter(({name, description}) =>
          this.$utils.queryStrAtr(this.connectorQuery, {name, description})
        )
        .filter((item) => {
          return !item.base_model && isMQTT(item);
        });
    },
    parsedConnectorList() {
      return this.filteredConnectorList.map((item) => ({
        id: item.id,
        name: item.name,
        description: item.description,
        checked:
          (this.formData.allowed_connectors_ids || []).indexOf(item.id) >= 0
      }));
    },
    allChecked() {
      return (
        this.parsedConnectorList.filter(({checked}) => checked).length ==
        this.filteredConnectorList.length
      );
    },
    sortedItens() {
      return (this.items || []).sort((a, b) => a.id - b.id);
    }
  },
  watch: {
    contract(n, o) {
      if (!o && n) {
        if (!this.items.length) {
          this.fetchTokens();
        }
        if (!this.connectorList.length) {
          this.fetchConectorList();
        }
      }
    },
    connectorList(n, o) {
      if (n.length && !this.items.length) {
        this.fetchTokens();
      }
    }
  },
  methods: {
    onCommand(command) {
      if (command.name in this) {
        this[command.name](command.target);
      }
    },
    payload(item) {
      let payload = JSON.parse(JSON.stringify(item));
      payload.contract_id = this?.contract?.id || "";
      if (!payload.id) {
        delete payload.token;
        delete payload.etag;
        delete payload.id;
      }
      return payload;
    },
    save(entry) {
      if (this.busy || !entry) return;
      this.busy = true;
      let payload = this.payload(entry);
      this.srv.save(payload).then((response) => {
        if (response) {
          if (this.showForm) {
            this.addNew();
          }
          this.fetchTokens();
        }
      });
    },
    remove(item) {
      if (this.busy) return;
      let entry = item || null;
      this.busy = true;
      this.srv.remove(entry).then(() => {
        if (this.showForm) {
          this.addNew();
        }
        this.fetchTokens();
      });
    },
    copy(item) {
      this.copyToClipboard(item.token);
    },
    edit(item) {
      for (var p in this.formData) {
        this.formData[p] = item[p];
      }
      this.showForm = true;
    },
    addNew() {
      this.reset();
      this.showForm = true;
      this.$nextTick(() => {
        if (this.$refs.description) {
          this.$refs.description.focus();
        }
      });
    },
    reset() {
      let data = initialState();
      this.$set(this, "formData", data.formData);
      this.connectorQuery = "";
    },
    copyToClipboard(text) {
      this.clipboardText = text;
      this.$utils.toClipboard(text);
      let infoHtml = this.$t("item_copied");
      let options = {
        type: "success",
        icon: "copy",
        iconPack: "fontawesome",
        singleton: true,
        duration: 10000, // 10s
        position: "bottom-right",
        action: {
          icon: "fa-close",
          onClick: (e, me) => {
            me.goAway(0);
          }
        }
      };
      this.$toasted.clear();
      this.$toasted.show(infoHtml, options);
    },
    toggleItem(item) {
      // this.edit(item);
      item.enabled = !item.enabled;
      this.save(item);
    },
    toggleConnector(connectorId) {
      let lst;
      if (connectorId) {
        // single connector
        lst = this.formData.allowed_connectors_ids || [];
        let ix = lst.indexOf(connectorId);
        if (ix >= 0) {
          lst.splice(ix, 1);
        } else {
          lst.push(connectorId);
        }
      } else {
        // all connectors
        if (this.allChecked) {
          lst = [];
        } else {
          lst = this.filteredConnectorList.map(({id}) => id);
        }
      }
      this.$set(this.formData, "allowed_connectors_ids", lst);
    },
    fetchTokens() {
      let query = {contract_id: this.contract.id};
      this.busy = true;
      this.srv.fetch(query).then((response) => {
        if (response && response.length) {
          response = response.map((item) => {
            return {
              ...item,
              ...{
                allowed_connectors_ids: (item.allowed_connectors || []).map(
                  ({id}) => id
                )
              }
            };
          });
          this.$set(this, "items", response);
        } else {
          this.$set(this, "items", []);
        }
        this.$nextTick(() => {
          this.busy = false;
        });
      });
    },
    fetchConectorList() {
      this.$store.dispatch("fetchEquipmentList");
    }
  },
  created() {
    this.srv = new TokenService("access_token");
    this.fetchTokens();
    if (!this.connectorList.length) {
      this.fetchConectorList();
    }
  }
};
</script>

<style scoped>
.me {
  min-height: 200px;
}

div.table-title {
  padding: 0 0 5px 0;
  margin-bottom: 5px;
  border-bottom: 1px solid #cdcdcd;
}

div.table-title .label {
  font-size: 14pt;
  color: #777;
  text-align: left;
}

.searchable-table {
  overflow-x: auto;
  margin-top: -10px;
}

.group-list {
  padding: 10px;
  border: 1px solid #cdcdcd;
  border-radius: 5px;
  min-height: 30dvh;
  overflow-y: auto;
  margin-top: -4px;
  border-top: 0;
}

.group-list > .item {
  font-weight: 600;
  vertical-align: middle;
}

.clicable:hover {
  cursor: pointer;
  opacity: 0.8;
}
.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
}
.form-control-feedback {
  pointer-events: all;
}
.formData {
  margin-bottom: 40px;
}
.fa-square-o {
  padding-right: 2px;
}

.token-cell > .copy-icon {
  color: transparent;
  margin-left: 10px;
}

.token-cell:hover > .copy-icon {
  color: inherit;
}
</style>
