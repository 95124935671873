<template>
  <div class="radio no-select" :id="id" :title="$attrs.title">
    <template v-for="(op, ix) in options">
      <label :for="`${id}_${op.value}`" :key="ix">
        <input
          :id="`${id}_${op.value}`"
          type="radio"
          v-bind="$attrs"
          v-on="listeners"
          :title="op.description || op.title"
          :name="id"
          :value="op.value"
          :checked="op.value == value"
          :indeterminate.prop="indeterminate"
          @input="update"
        />
        {{ op.title || op.description }}
      </label>
      &nbsp;
    </template>
  </div>
</template>

<script>
import omit from "lodash/omit";

export default {
  name: "Radio",
  inheritAttrs: false,
  model: {
    prop: "value",
    event: "change"
  },
  props: {
    id: {
      type: String,
      default: ""
    },
    value: {
      type: String,
      default: ""
    },
    options: {
      type: Array,
      required: true,
      default: () => []
    },
    indeterminate: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    listeners() {
      return omit(this.$listeners, "change");
    }
  },
  methods: {
    update(e) {
      this.$emit("change", e.target.value);
    }
  }
};
</script>

<style scoped>
.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
}
</style>
