<template>
  <li class="dropdown user user-menu no-select" v-if="can">
    <a
      href="#"
      class="dropdown-toggle"
      data-toggle="dropdown"
      data-testid="profile-menu"
    >
      <i v-if="busy" class="fa fa-refresh fa-spin"></i>
      <div v-else>
        <i class="fa fa-user"></i>
        <span class="hidden-xs hidden-sm hidden-md user-email">{{
          userEmail
        }}</span>
        <ContractAlertIcon
          visibility="profile"
          class="contract-alert-icon hidden-xs hidden-sm"
        />
      </div>
    </a>
    <ul class="dropdown-menu">
      <li class="user-header">
        <p>
          {{ userFullName }}
          <small>{{ userEmail }}</small>
        </p>
      </li>
      <li
        class="user-body plan_access hidden-xs hidden-sm"
        v-on:click.stop.prevent
      >
        <div class="row">
          <div class="col-xs-12">
            <div class="form-group form-group-sm" style="margin: 5px 0 0 0">
              <label style="font-size: 90%">
                {{ $t("contract") }}
              </label>
              <v-select
                class="contract-selector"
                v-on:click.stop.prevent="
                  () => {
                    false;
                  }
                "
                v-if="contractId"
                v-model="contractId"
                :options="contract_list"
                :reduce="({id}) => id"
                label="name"
              >
                <template #selected-option="item">
                  <div style="display: flex; align-items: baseline">
                    <span>
                      {{ item.name || $t("contract") }}
                      #{{ item.id }}
                    </span>
                  </div>
                </template>
                <template slot="option" slot-scope="option">
                  <span class="contract-selector-option">
                    {{ option.name || $t("contract") }} #{{ option.id }}
                  </span>
                </template>
                <template #no-options>
                  <span style="font-size: 90%">
                    {{ $t("no_result_found") }}
                  </span>
                </template>
              </v-select>
              <input
                v-else
                class="form-control"
                disabled
                v-bind:value="$t('loading')"
              />
            </div>
          </div>
        </div>
      </li>
      <!-- begin desktop -->
      <li class="user-footer hidden-xs hidden-sm">
        <div class="row hidden-xs hidden-sm">
          <div class="col-xs-4 text-left">
            <router-link
              to="/dashboard/settings/profile"
              class="btn btn-default btn-block btn-flat"
              v-if="showProfile"
            >
              <span>
                <Icon name="fa fa-user" />
                {{ $t("profile") }}
              </span>
            </router-link>
          </div>
          <div class="col-xs-4 text-center">
            <router-link
              to="/dashboard/settings/billing"
              data-testid="my-plan"
              class="btn btn-default btn-block btn-flat"
              v-if="showMyPlan"
            >
              <span>
                <Icon name="fa fa-credit-card" />
                {{ $t("plan") }}
              </span>
            </router-link>
          </div>
          <div class="col-xs-4 text-right">
            <a
              href="javascript:void(0);"
              v-on:click="logout"
              data-testid="logout"
              class="btn btn-default btn-block btn-flat"
            >
              <Icon name="fa fa-sign-out" />
              {{ $t("sign_out") }}
            </a>
          </div>
        </div>
      </li>
      <!-- end desktop -->

      <!-- begin mobile -->
      <li class="user-footer hidden-lg hidden-md">
        <div
          class="list-group"
          v-on:click.stop.prevent
          style="background-color: white"
        >
          <div class="list-group-item" style="padding: 5px 5px 10px 5px">
            <div class="small" style="">
              {{ $t("contract") }}
            </div>
            <v-select
              class="contract-selector"
              v-on:click.stop.prevent="
                () => {
                  false;
                }
              "
              v-if="contractId"
              v-model="contractId"
              :options="contract_list"
              :reduce="({id}) => id"
              label="name"
            >
              <template #selected-option="item">
                <div style="display: flex; align-items: baseline">
                  <span>
                    {{ item.name || $t("contract") }}
                    #{{ item.id }}
                  </span>
                </div>
              </template>
              <template slot="option" slot-scope="option">
                <span class="contract-selector-option">
                  {{ option.name || $t("contract") }} #{{ option.id }}
                </span>
              </template>
              <template #no-options>
                <span style="font-size: 90%">
                  {{ $t("no_result_found") }}
                </span>
              </template>
            </v-select>
            <input
              v-else
              class="form-control"
              disabled
              v-bind:value="$t('loading')"
            />
            <div class="text-center small text-danger">
              <ContractAlertIcon visibility="profile" :inline="true" />
            </div>
          </div>
          <a
            class="list-group-item"
            href="javascript:void(0);"
            v-for="option in menuOptions"
            :key="option.id"
            @click.stop="goto(option.path)"
          >
            <span>
              <Icon :name="option.icon" />
              {{ option.title }}
            </span>
          </a>
          <a
            href="javascript:void(0);"
            class="list-group-item"
            v-on:click="logout"
          >
            <Icon name="fa fa-sign-out" />
            {{ $t("sign_out") }}
          </a>
        </div>
        <!-- end mobile -->
      </li>
    </ul>
  </li>
</template>

<script>
import ContractAlertIcon from "@/components/contract-alert-icon.vue";
import Icon from "@/components/icons/icon.vue";

export class Menu {
  constructor($vm) {
    this.$vm = $vm;
  }
  contract() {
    return this.$vm.$store.getters["user/contract"] || null;
  }
  isAccountLocked() {
    this.$vm.$store.getters["user/isAccountLocked"];
  }
  isRoot() {
    return (this.$vm.$store.getters["user/loggedUser"] || {}).is_root || false;
  }
  options() {
    let lst = [];
    if (!this.contract()) return lst;
    let otp = this.$vm.$root?.config?.references?.otp;
    if (this.isAccountLocked()) {
      if (this.isRoot()) {
        lst.push({
          id: "billing",
          title: this.$vm.$t("my_plan"),
          icon: "fa fa-credit-card"
        });
      }
      return lst;
    }
    lst.push({
      id: "profile",
      title: this.$vm.$t("profile"),
      icon: "fa fa-user"
    });
    if (
      otp &&
      otp?.otp_config?.required &&
      !(otp?.otp_config?.app_enabled || otp?.otp_config?.email_enabled)
    ) {
      otp.otp_config.email_enabled = true;
    }
    if (
      otp &&
      otp.otp_enabled &&
      (otp?.otp_config?.app_enabled || otp?.otp_config?.email_enabled)
    ) {
      lst.push({
        id: "security",
        title: this.$vm.$t("security"),
        icon: "fa fa-lock"
      });
    }
    lst.push({
      id: "preferences",
      title: this.$vm.$t("preferences"),
      icon: " fa fa-star"
    });
    if (this.$vm.$can("manage", "ContratoEscrita")) {
      lst.push(
        ...[
          {
            id: "contract_settings",
            title: this.$vm.$t("contract_settings"),
            icon: "fa fa-cogs"
          },
          {
            id: "extended_properties",
            title: this.$vm.$t("extended_properties"),
            icon: "fa fa-list-ul"
          }
        ]
      );
      if (this?.$vm?.$root?.config?.custom_access_key) {
        lst.push({
          id: "access_key",
          title: this.$vm.$tc("access_key", 2),
          icon: "fa fa-key"
        });
      }
    }
    if (this.isRoot()) {
      lst.push({
        id: "billing",
        title: this.$vm.$t("my_plan"),
        icon: "fa fa-credit-card"
      });
    }
    return lst.map((i) => {
      i.path = `/dashboard/settings/${i.id.replace("_", "-")}`;
      return i;
    });
  }
}

export default {
  name: "ProfileDropDownMenu",
  components: {
    ContractAlertIcon,
    Icon
  },
  data() {
    return {
      ready: false,
      contract_id: "",
      reload: false
    };
  },
  computed: {
    contract() {
      return this.$store.getters["user/contract"] || null;
    },
    contractId: {
      set(value) {
        if (value && parseInt(this.contract_id) != parseInt(value)) {
          this.$utils
            .confirm(this, this.$t("you_have_unsaved_changes"))
            .then((ok) => {
              if (!ok) {
                let bkp = this.contract_id;
                this.contract_id = "";
                this.$nextTick(() => {
                  this.contract_id = bkp;
                });
                return;
              }
              this.contract_id = value;
            });
        }
      },
      get() {
        return this.contract_id;
      }
    },
    can() {
      return (this.isLogged && !this.user.is_public_access) || false;
    },
    isAccountLocked() {
      return this.$store.getters["user/isAccountLocked"];
    },
    showProfile() {
      return !this.isAccountLocked;
    },
    showMyPlan() {
      return (
        this.$root.config &&
        this.$root.config.plan_upgrade &&
        (this.isRoot || (this.isDemo && !this.doesUserOwnAContract))
      );
    },
    isRoot() {
      return (this.user && this.user.is_root) || false;
    },
    isDemo() {
      return (
        (this.user && this.user.contract && this.user.contract.is_demo) || false
      );
    },
    doesUserOwnAContract() {
      // returns true if user own any contract
      let user_id = (this.user && this.user.id) || "";
      if (user_id) {
        let contract_list = this.contract_list;
        for (var i in contract_list) {
          if (contract_list[i].owner_id == user_id) {
            return true;
          }
        }
      }
      return false;
    },
    user() {
      return this.$store.getters["user/loggedUser"] || null;
    },
    contract_list() {
      return (this.user && this.user.is_member_contracts) || [];
    },
    busy() {
      return (
        !this.isReady ||
        (this.user &&
          this.contract_id != "" &&
          this.user.contract_id != this.contract_id) ||
        false
      );
    },
    isReady: function () {
      return this.$store.getters["dashboard/isReady"];
    },
    isLogged: function () {
      return this.user != null;
    },
    userEmail: function () {
      return this.user.email || "email?";
    },
    userFullName: function () {
      let ret =
        (this.user.first_name || "") + " " + (this.user.last_name || "");
      return ret;
    },
    userPicture: function () {
      let ret =
        (this.$store.getters["user/loggedUser"] &&
          this.$store.getters["user/loggedUser"].picture) ||
        "/static/common/images/nopicture.gif";
      return ret;
    },
    img_banner: function () {
      return (
        this.$store.getters["dashboard/property"]("company_banner") ||
        "/static/common/images/hitecnologia_logo.png"
      );
    },
    img_logo: function () {
      return (
        this.$store.getters["dashboard/property"]("company_logo") ||
        "/static/common/images/hitecnologia_mascot_70.png"
      );
    },
    title: function () {
      return (
        this.$store.getters["dashboard/property"]("company_name") ||
        "<b>HI</b> Tecnologia"
      );
    },
    website: function () {
      return (
        this.$store.getters["dashboard/property"]("company_website") ||
        "https://hitecnologia.com.br"
      );
    },
    menuOptions() {
      return (this.ready && this.menu.options()) || [];
    }
  },
  watch: {
    user: {
      handler(n, o) {
        if (this.contract_id == "") {
          this.contract_id = n.contract_id;
        }
      },
      deep: true
    },
    contract: {
      handler(n, o) {
        if (!this.menu && n) {
          this.menu = new Menu(this);
          this.ready = true;
        }
      },
      immediate: true,
      deep: true
    },
    contract_id(n, o) {
      if (n != "" && o != "" && parseInt(o) != parseInt(n)) {
        this.$store.dispatch("user/clearUserDependencies");
        this.$store.dispatch("user/clearContractDependencies");
        this.$store
          .dispatch("user/updateActiveContract", n)
          .then(() => {
            if (this.$route.path != "/dashboard/search") {
              this.$router.push(`/?redirect_url=${this.$route.path}`);
              this.reload = true;
            }
          })
          .catch(() => {
            // It has been probably updated while in another tab
            this.$router.push("/dashboard/search");
            this.reload = true;
          });
      }
    },
    reload(n) {
      if (!n) return;
      this.reload = false;
      document.location.reload();
    }
  },
  methods: {
    logout() {
      this.$store.dispatch("user/logout");
      this.$store.dispatch("reset");
      this.$router.push("/");
    },
    goto(path) {
      if (path != this.$route.path) {
        this.$router.push(path);
      }
      this.$root.$emit("controlSidebar:setContent", null);
      this.$el.classList.remove("open");
    }
  },
  created() {
    if (this.user && this.user.contract_id) {
      this.contract_id = this.user.contract_id;
    }
  }
};
</script>

<style scoped>
.user-email {
  margin-left: 5px;
}

@media (max-width: 991px) {
  a.dropdown-toggle {
    height: 50px;
  }
  .navbar-custom-menu > .navbar-nav > li > .dropdown-menu {
    right: 5px;
  }
}

@media (min-width: 992px) and (max-width: 1250px) {
  .hidden-md {
    display: none !important;
  }
}

@media (max-width: 767px) {
  .navbar-nav > .user-menu > .dropdown-menu > .user-footer {
    padding: 0;
  }
}

.user-footer .col-xs-4 {
  padding: 10px;
}

.user-footer > .list-group {
  padding: 0;
}

.nowrap {
  white-space: nowrap;
}

.skin-black-light .main-header li.user-header {
  background-color: #dbe0ea !important;
}

.navbar-nav > .user-menu > .dropdown-menu {
  box-shadow: 2px 4px 6px rgb(195, 195, 195);
}

.navbar-nav > .user-menu > .dropdown-menu > li.user-header {
  height: auto;
  color: black;
}
.navbar-nav > .user-menu > .dropdown-menu > li.user-header > p {
  color: inherit;
}

.navbar-nav > .user-menu > .dropdown-menu > .user-body {
  padding: 5px 15px;
  border-bottom: 1px solid #f4f4f4;
  border-top: 1px solid #dddddd;
}

.select-contract {
  width: auto;
  max-width: 100px;
  padding: 2px 4px;
  border: 0;
  background-color: white;
  font-size: 0.8em;
}

.plan_access {
  background-color: whitesmoke;
}
.contract-alert-icon {
  float: left;
  margin: 0 10px 0 0;
}
.contract-selector-option {
  font-size: 90%;
}
.list-group-item i {
  min-width: 16px;
  margin-right: 5px;
}
>>> {
  /* custom */
  --vs-dropdown-option-padding: 3px 10px 5px 10px;
  --vs-line-height: 1.2rem;
}
</style>
<style>
.contract-selector .vs__dropdown-toggle .vs__selected {
  font-size: 80%;
  padding: 0;
  line-height: 1em;
  white-space: nowrap;
  max-width: 100%;
  width: calc(100% - 15px);
  overflow: hidden;
  text-overflow: ellipsis;
}
.contract-selector .vs__dropdown-toggle .vs__search {
  padding: 0 4px;
  font-size: 80%;
  margin: 0;
}
</style>
